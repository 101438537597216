import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";
import { isLoggedIn, requireAccessControl } from "../../authConfig";

import { AttachFilled, DismissCircle24Regular } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";

interface Props {
    onSend: (question: string, file: File | null) => void;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, initQuestion }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        initQuestion && setQuestion(initQuestion);
    }, [initQuestion]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        let file = selectedFile;

        onSend(question, file);

        if (clearOnSend) {
            setQuestion("");
            setSelectedFile(null);
            // Reset the file input value
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 10000) {
            setQuestion(newValue);
        }
    };

    const { instance } = useMsal();
    const disableRequiredAccessControl = requireAccessControl && !isLoggedIn(instance);
    const sendQuestionDisabled = disabled || !question.trim() || disableRequiredAccessControl;

    if (disableRequiredAccessControl) {
        placeholder = "Please login to continue...";
    }

    const onAttachClick = () => {
        if (fileInputRef !== null && fileInputRef.current !== null) fileInputRef.current.click();
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const onRemoveFile = () => {
        setSelectedFile(null);
        // Reset the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <div>
            {/* Display the selected file */}
            {selectedFile && (
                <Stack horizontal verticalAlign="center" className={styles.selectedFileContainer}>
                    <span className={styles.selectedFileName}>{selectedFile.name}</span>
                    <Button
                        icon={<DismissCircle24Regular />}
                        onClick={onRemoveFile}
                        className={styles.removeFileButton}
                        title="Remove file"
                        aria-label="Remove file"
                    />
                </Stack>
            )}

            <Stack horizontal className={styles.questionInputContainer}>
                {/* Attach Button */}
                <div className={styles.questionInputButtonsContainer}>
                    <Tooltip content="Attach file" relationship="label">
                        <Button
                            size="large"
                            icon={<AttachFilled primaryFill="rgba(115, 118, 225, 1)" />}
                            onClick={onAttachClick}
                            disabled={disableRequiredAccessControl}
                        />
                    </Tooltip>
                </div>

                {/* Hidden file input */}
                <input type="file" accept=".docx,.pdf, .pptx" ref={fileInputRef} style={{ display: "none" }} onChange={onFileChange} />

                <TextField
                    className={styles.questionInputTextArea}
                    disabled={disableRequiredAccessControl}
                    placeholder={placeholder}
                    resizable={false}
                    multiline
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <Tooltip content="Ask question button" relationship="label">
                        <Button
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </Tooltip>
                </div>
            </Stack>
        </div>
    );
};
