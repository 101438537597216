import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Slider } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import readNDJSONStream from "ndjson-readablestream";
import { getHeaders } from "../../api";

import logo from "../../assets/gpt-image.png";
import styles from "./Chat.module.css";

import uuid from "react-uuid";

import {
    chatApi,
    configApi,
    RetrievalMode,
    ChatAppResponseOrError,
    ChatAppRequest,
    BotAppResponse,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput
} from "../../api";
import { AnswerBot, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/ExampleCustom";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { UploadFile } from "../../components/UploadFile";
import { useLogin, getToken, isLoggedIn, requireAccessControl } from "../../authConfig";
import { VectorSettings } from "../../components/VectorSettings";
import { useMsal } from "@azure/msal-react";
import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { GPT4VSettings } from "../../components/GPT4VSettings";
import { RadioGroup, Radio, Field } from "@fluentui/react-components";
import { Brush, Scale } from "lucide-react";

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.3);
    const [minimumRerankerScore, setMinimumRerankerScore] = useState<number>(0);
    const [minimumSearchScore, setMinimumSearchScore] = useState<number>(0);
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding]);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.TextAndImages);
    const [useGPT4V, setUseGPT4V] = useState<boolean>(false);
    const [conversationStyle, setConversationStyle] = useState("creative");
    const [chat_id, setChatID] = useState<string>(uuid().toString());

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: BotAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: BotAppResponse][]>([]);
    const [showGPT4VOptions, setShowGPT4VOptions] = useState<boolean>(false);
    const [showSemanticRankerOption, setShowSemanticRankerOption] = useState<boolean>(false);
    const [showVectorOption, setShowVectorOption] = useState<boolean>(false);
    const [showUserUpload, setShowUserUpload] = useState<boolean>(false);

    const getConfig = async () => {
        const config = configApi();

        setShowGPT4VOptions(config.showGPT4VOptions);
        setUseSemanticRanker(config.showSemanticRankerOption);
        setShowSemanticRankerOption(config.showSemanticRankerOption);
        setShowVectorOption(config.showVectorOption);
        if (!config.showVectorOption) {
            setRetrievalMode(RetrievalMode.Text);
        }
        setShowUserUpload(config.showUserUpload);
    };

    // const handleAsyncRequest = async (question: string, answers: [string, BotAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
    //     let answer: string = "";
    //     let askResponse: ChatAppResponse = {} as ChatAppResponse;

    //     const updateState = (newContent: string) => {
    //         return new Promise(resolve => {
    //             setTimeout(() => {
    //                 answer += newContent;
    //                 const latestResponse: ChatAppResponse = {
    //                     ...askResponse,
    //                     choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
    //                 };
    //                 setStreamedAnswers([...answers, [question, latestResponse]]);
    //                 resolve(null);
    //             }, 33);
    //         });
    //     };
    //     try {
    //         setIsStreaming(true);
    //         for await (const event of readNDJSONStream(responseBody)) {
    //             if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
    //                 event["choices"][0]["message"] = event["choices"][0]["delta"];
    //                 askResponse = event as ChatAppResponse;
    //             } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
    //                 setIsLoading(false);
    //                 await updateState(event["choices"][0]["delta"]["content"]);
    //             } else if (event["choices"] && event["choices"][0]["context"]) {
    //                 // Update context with new keys from latest event
    //                 askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
    //             } else if (event["error"]) {
    //                 throw Error(event["error"]);
    //             }
    //         }
    //     } finally {
    //         setIsStreaming(false);
    //     }
    //     const fullResponse: ChatAppResponse = {
    //         ...askResponse,
    //         choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
    //     };
    //     return fullResponse;
    // };

    const client = useLogin ? useMsal().instance : undefined;

    const makeApiRequest = async (question: string, selectedFile: File | null) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        const token = client ? await getToken(client) : undefined;

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = async () => {
                let content = reader.result;

                if (
                    selectedFile.type === "application/pdf" ||
                    selectedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    selectedFile.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                ) {
                    // For PDFs and DOCX files, encode the binary content to base64
                    content = btoa(new Uint8Array(content as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                }

                const payload = {
                    question: question,
                    fileName: selectedFile.name,
                    fileType: selectedFile.type,
                    fileContent: content
                };

                try {
                    const messages: ResponseMessage[] = answers.flatMap(a => [
                        { content: a[0], role: "user" },
                        { content: a[1].message, role: "assistant" }
                    ]);

                    const request: ChatAppRequest = {
                        messages: [...messages, { content: question, role: "user" }],
                        // stream: shouldStream,
                        // context: {
                        //     overrides: {
                        //         prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                        //         exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        //         top: retrieveCount,
                        //         temperature: temperature,
                        //         minimum_reranker_score: minimumRerankerScore,
                        //         minimum_search_score: minimumSearchScore,
                        //         retrieval_mode: retrievalMode,
                        //         semantic_ranker: useSemanticRanker,
                        //         semantic_captions: useSemanticCaptions,
                        //         suggest_followup_questions: useSuggestFollowupQuestions,
                        //         use_oid_security_filter: useOidSecurityFilter,
                        //         use_groups_security_filter: useGroupsSecurityFilter,
                        //         vector_fields: vectorFieldList,
                        //         use_gpt4v: useGPT4V,
                        //         gpt4v_input: gpt4vInput
                        //     }
                        // },
                        // ChatAppProtocol: Client must pass on any session state received from the server
                        // session_state: null,
                        chat_id: chat_id,
                        conversation_style: conversationStyle,
                        fileName: selectedFile.name,
                        fileType: selectedFile.type,
                        file: content
                    };

                    const response = await chatApi(request, token);
                    if (!response.body) {
                        throw Error("No response body");
                    }

                    const parsedResponse: ChatAppResponseOrError = await response.json();
                    if (response.status > 299 || !response.ok) {
                        throw Error(parsedResponse.error || "Unknown error");
                    }
                    setAnswers([...answers, [question, parsedResponse as BotAppResponse]]);
                } catch (e) {
                    setError(e);
                } finally {
                    setIsLoading(false);
                }
            };

            if (
                selectedFile.type === "application/pdf" ||
                selectedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                selectedFile.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
            ) {
                reader.readAsArrayBuffer(selectedFile);
            } else {
                alert("Unsupported file type");
            }
        } else {
            try {
                const messages: ResponseMessage[] = answers.flatMap(a => [
                    { content: a[0], role: "user" },
                    { content: a[1].message, role: "assistant" }
                ]);

                const request: ChatAppRequest = {
                    messages: [...messages, { content: question, role: "user" }],
                    // stream: shouldStream,
                    // context: {
                    //     overrides: {
                    //         prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                    //         exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                    //         top: retrieveCount,
                    //         temperature: temperature,
                    //         minimum_reranker_score: minimumRerankerScore,
                    //         minimum_search_score: minimumSearchScore,
                    //         retrieval_mode: retrievalMode,
                    //         semantic_ranker: useSemanticRanker,
                    //         semantic_captions: useSemanticCaptions,
                    //         suggest_followup_questions: useSuggestFollowupQuestions,
                    //         use_oid_security_filter: useOidSecurityFilter,
                    //         use_groups_security_filter: useGroupsSecurityFilter,
                    //         vector_fields: vectorFieldList,
                    //         use_gpt4v: useGPT4V,
                    //         gpt4v_input: gpt4vInput
                    //     }
                    // },
                    // // ChatAppProtocol: Client must pass on any session state received from the server
                    // session_state: null,
                    chat_id: chat_id,
                    conversation_style: conversationStyle
                };

                const response = await chatApi(request, token);
                if (!response.body) {
                    throw Error("No response body");
                }

                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as BotAppResponse]]);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);
    useEffect(() => {
        getConfig();
    }, []);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onTemperatureChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setTemperature(newValue);
    };

    const onMinimumSearchScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumSearchScore(parseFloat(newValue || "0"));
    };

    const onMinimumRerankerScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumRerankerScore(parseFloat(newValue || "0"));
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example, null);
    };

    const onCoversationStyleChange = (event: any) => {
        setConversationStyle(event.target.value);
    };

    const fetchCitation = async (activeCitation: string) => {
        const token = client ? await getToken(client) : undefined;
        if (activeCitation) {
            console.log("");
            // Get hash from the URL as it may contain #page=N
            // which helps browser PDF renderer jump to correct page N
            const originalHash = activeCitation.indexOf("#") ? activeCitation.split("#")[1] : "";
            const response = await fetch(activeCitation, {
                method: "GET",
                headers: getHeaders(token)
            });

            const citationContent = await response.blob();

            const blobURL = window.URL.createObjectURL(citationContent);
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", activeCitation);
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            URL.revokeObjectURL(blobURL);
        }
    };

    const onShowCitation = (citation: string, index: number) => {
        //console.log("Clicked citation", citation, activeAnalysisPanelTab, selectedAnswer);
        if (citation.indexOf(".docx") > 0) {
            fetchCitation(citation);
        } else {
            if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
                //console.log("Here 1");
                setActiveAnalysisPanelTab(undefined);
            } else {
                //console.log("Here 2");
                setActiveCitation(citation);
                setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
                //fetchCitation(citation)
            }
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                {showUserUpload && <UploadFile className={styles.commandButton} disabled={!isLoggedIn(client)} />}
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <h1 className={styles.chatEmptyStateTitle}>Chat with our data</h1>
                            <div className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</div>
                            <ExampleList onExampleClicked={onExampleClicked} useGPT4V={useGPT4V} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <img src={logo} alt="Gpt" height="45px" width="45px" />
                                        <AnswerBot
                                            isStreaming={false}
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            // onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <img src={logo} alt="Gpt" height="45px" width="45px" />
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <img src={logo} alt="Gpt" height="45px" width="45px" />
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current, null)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question (e.g.  how does UNDP Serbia contribute to the SDG Agenda?)"
                            disabled={isLoading}
                            onSend={(question, file) => makeApiRequest(question, file)}
                        />
                    </div>
                </div>

                {activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Conversation Type"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <Field style={{ marginTop: 16 }} className={lastQuestionRef.current == "" ? "" : styles.disabledField}>
                        <RadioGroup onChange={onCoversationStyleChange} defaultValue={conversationStyle} disabled={lastQuestionRef.current != ""}>
                            <Radio
                                value="creative"
                                label={
                                    <>
                                        <Brush size={16} style={{ marginRight: 8 }} />
                                        Main{" "}
                                        <p className={styles.radioDescription}>
                                            Uses semantic databases and is designed to be more creative in text generation. It likely leverages natural language
                                            understanding to provide more contextually rich and nuanced responses. This type of conversation is ideal for tasks
                                            that require a more conversational and flexible approach, such as talking points, content creation, or personal
                                            assistance.
                                        </p>
                                    </>
                                }
                                className={`${styles.customRadio} ${conversationStyle === "creative" ? styles.selected : ""}`}
                            />
                            <Radio
                                value="balanced"
                                label={
                                    <>
                                        <Scale size={16} style={{ marginRight: 8 }} />
                                        Precise{" "}
                                        <p className={styles.radioDescription}>
                                            It is more structured and focused on retrieving and reporting information from a database. It’s designed for
                                            accuracy and consistency, making it suitable for tasks where precision and factual correctness are paramount, such
                                            as generating reports, providing factual answers, or handling tasks where specific data retrieval is required.
                                        </p>
                                    </>
                                }
                                className={`${styles.customRadio} ${conversationStyle === "balanced" ? styles.selected : ""}`}
                            />
                        </RadioGroup>
                    </Field>
                    {/* <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <Slider
                        className={styles.chatSettingsSeparator}
                        label="Temperature"
                        min={0}
                        max={1}
                        step={0.1}
                        defaultValue={temperature}
                        onChange={onTemperatureChange}
                        showValue
                        snapToStep
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum search score"
                        min={0}
                        step={0.01}
                        defaultValue={minimumSearchScore.toString()}
                        onChange={onMinimumSearchScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum reranker score"
                        min={1}
                        max={4}
                        step={0.1}
                        defaultValue={minimumRerankerScore.toString()}
                        onChange={onMinimumRerankerScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />

                    {showSemanticRankerOption && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSemanticRanker}
                            label="Use semantic ranker for retrieval"
                            onChange={onUseSemanticRankerChange}
                        />
                    )}
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />

                    {showGPT4VOptions && (
                        <GPT4VSettings
                            gpt4vInputs={gpt4vInput}
                            isUseGPT4V={useGPT4V}
                            updateUseGPT4V={useGPT4V => {
                                setUseGPT4V(useGPT4V);
                            }}
                            updateGPT4VInputs={inputs => setGPT4VInput(inputs)}
                        />
                    )}

                    {showVectorOption && (
                        <VectorSettings
                            showImageOptions={useGPT4V && showGPT4VOptions}
                            updateVectorFields={(options: VectorFieldOptions[]) => setVectorFieldList(options)}
                            updateRetrievalMode={(retrievalMode: RetrievalMode) => setRetrievalMode(retrievalMode)}
                        />
                    )}

                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter || requireAccessControl}
                            label="Use oid security filter"
                            disabled={!isLoggedIn(client) || requireAccessControl}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter || requireAccessControl}
                            label="Use groups security filter"
                            disabled={!isLoggedIn(client) || requireAccessControl}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}

                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                    {useLogin && <TokenClaimsDisplay />} */}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
