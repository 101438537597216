import { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { BotAppResponse, getCitationFilePath } from "../../api";
import { parseBotAnswerToHtml } from "./AnswerParser";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import Markdown from "markdown-to-jsx";

interface Props {
    answer: BotAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const AnswerBot = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    //console.log("Answer", answer);
    const [isIconChecked, setIsIconChecked] = useState(false);
    // const followupQuestions =  answer.choices[0].context.followup_questions;
    const messageContent = answer.message;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const toggleIcon = () => {
        setIsIconChecked(prevState => !prevState);
    };

    const handleButtonClick = () => {
        toggleIcon();
        navigator.clipboard.writeText(messageContent);
        setTimeout(() => {
            toggleIcon();
        }, 3500);
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        {/*    <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.choices[0].context.thoughts?.length}
                        />
                        */}
                        {!isIconChecked ? (
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "ClipboardList" }}
                                title="Copy message to clipboard"
                                ariaLabel="Copy message to clipboard"
                                onClick={() => handleButtonClick()}
                            />
                        ) : (
                            <IconButton style={{ color: "black" }} iconProps={{ iconName: "CheckMark" }} />
                        )}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                {/* <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div> */}
                <Markdown>{sanitizedAnswerHtml}</Markdown>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { flexDirection: "column" } }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
